import React from 'react';
import './App.css';
import First from './pages/First';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/from/:source/since/:starting" component={First}/>
        <Route path="/from/:source/event/:event" component={First}/>
        <Route path="/from/:source" component={First}/>
        <Route path="/" component={First}/>
      </Switch>
    </Router>
  );
}

export default App;
