import React from 'react';
import {Col, Form, InputGroup} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import {useTranslation} from "react-i18next";
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import i18n from 'i18next';
import './FilterWrap.css';

class FilterForm extends React.Component {

  monthsData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((mm) => {
    return {
      id: mm,
      name: moment().add(mm, 'M').format('MMMM')
    };
  });

  months = this.monthsData.map((mon) => (
    <option
      key={mon.id}
      // eventKey={mon.id}
      value={mon.id}
    >{mon.name}</option>
  ));

  handleChangeMinDays = (res) => {
    this.props.handleChangeMinDays(res);
  };

  handleChangeMaxDays = (res) => {
    this.props.handleChangeMaxDays(res);
  };

  handleChangeStartingMonth = (res) => {
    this.props.handleChangeStartingMonth(res);
  };

  render() {
    return (
      <div>
        <Form.Row controlid="FilterForm.days" className="filter-row">
          <Form.Label className='text-muted text-left filter-text text-small' column sm="4">Travel length in days
            from </Form.Label>
          <Col sm="3">
            <InputGroup className="">
              <Form.Control type="number"
                            placeholder="1"
                            className="select-day"
                            aria-describedby="from-days"
                            value={this.props.MinDays}
                            onChange={this.handleChangeMinDays}/>
              <InputGroup.Append>
                <InputGroup.Text id="from-days">days</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Form.Label className='text-muted text-left filter-text text-small' column sm="0.5">to</Form.Label>
          <Col sm="3">
            <InputGroup className="">
              <Form.Control type="number"
                            placeholder="15"
                            className="select-day"
                            value={this.props.MaxDays}
                            onChange={this.handleChangeMaxDays}
                            aria-describedby="to-days"/>
              <InputGroup.Append>
                <InputGroup.Text sm="0.5" id="to-days">days</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Form.Row>
        <Form.Row>
          <Form.Label className='text-muted text-left filter-text text-small' sm="4" column>Start from</Form.Label>
          <Col sm={3}>
            <Form.Control
              as="select"
              value={this.props.StartingMonth}
              onChange={this.handleChangeStartingMonth}>
              {this.months}
            </Form.Control>
          </Col>
          <Col sm={0.5}/>
          <Col sm={3}/>
        </Form.Row>
      </div>
    );
  }
}


function CustomToggle({props, children, eventKey}) {
  const DecoratedOnClick = useAccordionToggle(eventKey); /*, () =>
    console.log("")
  );*/
  const {t, i18n} = useTranslation();

  const onChangeCheck = (event) => {
    let checked = event.target.checked;

    if (!checked) {
      props.handleChangeMinDays({target: {name: "", value: 1}});
      props.handleChangeMaxDays({target: {name: "", value: 15}});
      props.handleChangeStartingMonth({target: {name: "", value: 0}});
    }
  };

  return (
    <div key={`inline-checkbox`} className="mb-3 text-left">
      <Form.Check className='filters-style text-muted'
                  label="advanced filters"
                  type="checkbox"
                  id={`inline-checkbox-1`}
                  onChange={onChangeCheck}
                  onClick={DecoratedOnClick}/>
    </div>
  );
}

class FilterWrap extends React.Component {
  render() {
    return (
      <div className="filter-panel form-row justify-content-md-center">

        <Accordion defaultActiveKey="FilterPanelID" className='form-group inline'>
          <CustomToggle props={this.props} eventKey="1"/>
          <Accordion.Collapse eventKey="1">
            <FilterForm handleChangeMinDays={this.props.handleChangeMinDays} MinDays={this.props.MinDays}
                        handleChangeMaxDays={this.props.handleChangeMaxDays} MaxDays={this.props.MaxDays}
                        handleChangeStartingMonth={this.props.handleChangeStartingMonth}
                        StartingMonth={this.props.StartingMonth}
            />
          </Accordion.Collapse>
        </Accordion>

      </div>
    );
  }
}

export default FilterWrap;
