// import API from './api';

const LIST = [
  'LHR',
  'MUC',
  'VIE',
  'LGW',
  'BRU',
  'CIA',
  'TXL',
  'CGN',
  'DUB',
  'ARN',
  'OSL',
  'LIS',
  'DUS',
  'BUD',
  // 'BER',
  'PRG',
  'RIX',
  'STN',
  'MLA',
  'KBP',
  'WAW',
  'VNO',
  'MSQ',
  'OTP',
  'LTN',
  'KEF',
  'LCY',
  'BIO',
  'SVQ',
  'LEI',
  'FLR',
  'TFN',
  'VKO',
  'DME',
  'SVO',
  'LED',
  'SVX',
  'OVB',
  'KJA',
  'KHV',
  'KRR',
  'UFA',
  'AER',
  'PEK',
  'HKG',
  'SIN',
  'ICN',
  'BKK',
  'PVG',
  'NRT',
  'DEL',
  'KUL',
  'CGK',
  'TPE',
  'BOM',
  'MNL',
  'PEK',
  'DXB',
  'HND',
  'CAN',
  'ICN',
  'CTU',
  'AUH',
  'ADA',
  'ADB',
  // 'JWC',
  'ALA',
  'AYT',
  'ASB',
  'IST',
  'TLV',
  'MFM',
  'ISB',
  'ITM',
  'VDA',
  'HKT',
  'EVN',
  'JFK',
  'EWR',
  'LGA',
  'ATA',
  'ORD',
  'DFW',
  'DEN',
  'SFO',
  'SEA',
  'LAS',
  'MCO',
  'CLT',
  'PHX',
  'IAH',
  'DTW',
  'PHL'
];


let CAPITALS = {
  getRandom(n) {
    const shuffled = LIST.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, n);

    // let result = new Array(n),
    //   len = LIST.length,
    //   taken = new Array(len);
    //
    // if (n > len) {
    //   throw new RangeError("getRandom: more elements taken than available");
    // }
    //
    // while (n--) {
    //   const x = Math.floor(Math.random() * len);
    //   result[n] = LIST[x in taken ? taken[x] : x];
    //   taken[x] = --len in taken ? taken[len] : len;
    // }
    // return result;
  }
};

export default CAPITALS;
