import React, {Component} from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import moment from 'moment';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import VisibilitySensor from 'react-visibility-sensor';
import interactionPlugin from '@fullcalendar/interaction';
import {useTranslation} from "react-i18next";
import i18n from 'i18next';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import Iframe from 'react-iframe';

import './TravelItem.css';

const ACTIVE_ATTR = 'is-active';
const ACTIVE_STATE = 'active';
const INACTIVE_STATE = 'inactive';

function CustomToggle({props, children, eventKey, select}) {
  let element = document.getElementById(eventKey);
  const {t, i18n} = useTranslation();

  const decoratedOnClick = useAccordionToggle('detail-' + eventKey, () => buttonClicked(eventKey, select));
  let hidden = true;

  if (element && element.hasAttribute(ACTIVE_ATTR)) {
    hidden = element.getAttribute(ACTIVE_ATTR) === INACTIVE_STATE;
  }

  const buttonClicked = (eventKey, select) => {
    select();

    let element = document.getElementById(eventKey);
    if (!element) {
      return;
    }

    if (!element.hasAttribute(ACTIVE_ATTR)) {
      element.setAttribute(ACTIVE_ATTR, INACTIVE_STATE);
    }

    let to_show = element.getAttribute(ACTIVE_ATTR) === INACTIVE_STATE;

    console.log('click!', eventKey, to_show ? 'hidden' : 'shown');

    element.classList.add(!to_show ? 'border-dark' : 'border-primary');
    element.classList.remove(!to_show ? 'border-primary' : 'border-dark');

    if (hidden) {
      element.setAttribute(ACTIVE_ATTR, ACTIVE_STATE);
    } else {
      element.setAttribute(ACTIVE_ATTR, INACTIVE_STATE);
    }
  };

  return (
    <div className='close-btn'><Button className="text-white bg-primary"
                                       onClick={decoratedOnClick}>{hidden ? 'Show details' : 'Hide details'}</Button>
    </div>
  );
}

class TravelItem extends Component {
  iframeRef = React.createRef();
  divRef = React.createRef();
  state = {
    ticketClass: 'ticket-wrap',
    active: false
    // width: '45%'
  };

  detailId = 'detail-' + this.props.id;

  handleClick = () => {
    this.props.onSelect(this.props.travel);
  };

  onSelectDetails = () => {
    const newActive = !this.state.active;
    let newClass = newActive ? 'ticket-wrap-selected shadow-lg rounded' : 'ticket-wrap';

    this.setState({
      active: newActive,
      ticketClass: newClass
    });
  };

  render() {
    let {travel} = this.props;

    if (travel === undefined) {
      return null;
    }

    let country = travel.country;

    if (!travel.city) {
      console.log("Weird record: ", travel);
      return null;
    }

    let city = travel.city.name_translations.en;
    let airportName = travel.airport.name_translations.en;

    let image = travel.image_big;

    let departDate = moment(travel.depart_date).format('DD/MM/YYYY');
    let returnDate = moment(travel.return_date).format('DD/MM/YYYY');

    let events = [{
      id: travel.id,
      title: `${city}, ${country} $${travel.value}`,
      start: travel.depart_date,
      end: travel.return_date,
      className: 'bg-success text-white',
      travel: travel
    }];

    let calendarID = 'cal-' + travel.id;
    let stay22ID = 'stay22-' + travel.id;
    let detailId = 'detail-' + travel.id;
    let iframeId = 'if-' + travel.id;
    let divIframeId = 'div-if-' + travel.id;

    let calStart = moment(travel.depart_date).format('YYYY-MM-DD');
    let calEnd = moment(travel.return_date).format('YYYY-MM-DD');
    let stay22URL = `https://www.stay22.com/embed/gm?&address='${city}, ${country}'&checkin=${calStart}&checkout=${calEnd}`;
    let days = moment(travel.return_date).diff(moment(travel.depart_date), 'days');
    let urlFrom = `/from/${travel.airport.code}/since/${calStart}`;
    let iframeDiv = document.getElementById(iframeId);
    let containerDiv = document.getElementById("tr-list");

    const onChange = (isVisible) => {
      let ifr = <Iframe url={stay22URL}
                        width="100%"
                        loading='Loading..'
                        id={stay22ID}
                        className="stay22"
                        display="initial"
                        frameborder={0}
                        height="400px"
                        scrolling="no"
                        position="relative"/>;

      // console.log("Id %s (%s) : ", stay22URL, iframeId, isVisible ? 'visible' : 'invisible');
      if (!isVisible) {
        return;
      }

      ReactDOM.render(ifr, document.getElementById(iframeId));
    };

    return (
      <Accordion defaultActiveKey="0" className={this.state.ticketClass}>
        <Card className='ticket card border-dark'
              id={travel.id}
              ref={travel.ref}
          // className='ticket-wrap'
        >
          <Image src={image} className='card-img-top card-image'/>
          <Card.Title className="card-title container bg-light">
            <div className="text-muted text-center travel-note">
              {departDate}{' - '}{returnDate}: <span className="bold-font">{days} days</span>
            </div>
          </Card.Title>
          <Card.Body className="container card-body-country">
            <div className='row container'>
              <div className="col-md-6 container">
                <div className='text-left text-uppercase'>{city}, {country}</div>
                <div className="text-info text-left"><a href={urlFrom}><small>{airportName}</small></a></div>
              </div>
              <div className="col-md-6 text-center text-right">
                <span className="">${travel.value}</span></div>
            </div>
            <div className='row'>
              <div className="col-md-12 second-Book">
                <button className='btn btn-warning Book-btn' onClick={this.handleClick}>
                  <span className='text-big'>Book ${travel.value}</span><br/>
                  <div className="col-md-12 text-muted text-center">
                    on {departDate}{' - '}{returnDate}: <span className="bold-font">{days} days</span>
                  </div>
                </button>
              </div>
            </div>
          </Card.Body>
          <Card.Footer>
            <CustomToggle props={this.props}
                          eventKey={travel.id}
                          select={this.onSelectDetails}
            />
          </Card.Footer>

          <Accordion.Collapse eventKey={detailId}>
            <Card.Body className="container details">
              <div className='row'>
                <div className="col-md-12 item-calendar">
                  <span className='text-muted'>The travel calendar:</span>
                  <FullCalendar
                    defaultView="dayGridMonth"
                    width="100%"
                    heigh="400px"
                    plugins={[dayGridPlugin, interactionPlugin]}
                    weekends={true}
                    defaultDate={travel.depart_date}
                    events={events}
                    aspectRatio={2}
                    id={calendarID}
                  />
                </div>
              </div>
              <div className='row'>
                <div className="col-md-12 item-iframe">
                  <span className='text-muted'>The travel destination:</span>
                  <VisibilitySensor onChange={onChange}
                                    containment={containerDiv}
                    // scrollCheck = 'true'
                                    key={divIframeId}>
                    {({isVisible}) => {
                      return (
                        <div ref={this.iframeRef} id={iframeId}>blah</div>
                      );
                    }}
                  </VisibilitySensor>
                </div>
              </div>
              <div className='row'>
                <div className="col-md-12 second-Book">
                  <button className='btn btn-warning Book-btn' onClick={this.handleClick}>
                    <span className='text-big'>Book ${travel.value}</span><br/>
                    <div className="col-md-12 text-muted">
                      on {departDate}{' - '}{returnDate}: <span className="bold-font">{days} days</span>
                    </div>
                  </button>
                </div>
              </div>
              <div className='row'>
                <div className="col-md-12">
                  <CustomToggle eventKey={travel.id}
                                select={this.onSelectDetails}
                  />
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

TravelItem.TravelItem = {
  ticket: PropTypes.shape({
    id: PropTypes.string,
    gate: PropTypes.string,
    destination: PropTypes.number,
    depart_date: PropTypes.instanceOf(Date),
    return_date: PropTypes.instanceOf(Date),
    value: PropTypes.number,
    city: PropTypes.object,
    airport: PropTypes.object
  })
};

export default TravelItem;

/*

              <div className='row'>
                <div className="col-md-12">
                  <ReactWeather
                    forecast="today"
                    apikey="eab3f67ffd42b32d45dd51d227ac9773"
                    type="city"
                    city={city}/>
                </div>
              </div>

 */
