import React from 'react';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import './FilterLine.css';
import {Button, ButtonGroup, Dropdown, DropdownButton} from 'react-bootstrap';
// import moment from 'moment';
import {useTranslation} from "react-i18next";

import Accordion from 'react-bootstrap/Accordion';

class FilterLine extends React.Component {
  render() {
    return (
      <div className='row manage-panel-wrap'>
        <ButtonGroup className='text-enter manage-panel'>
          <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="mainCalendar">
            Show/Hide calendar
          </Accordion.Toggle>

          <DropdownButton as={ButtonGroup}
                          title="Order by"
                          id="bg-nested-dropdown"
                          variant="outline-secondary">
            <Dropdown.Item eventKey="1" onClick={() => {
              this.props.onSort('date');
            }}
            >Depart date</Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => {
              this.props.onSort('price');
            }}
            >Price</Dropdown.Item>
          </DropdownButton>
        </ButtonGroup>
      </div>
    );
  }
}

export default FilterLine;
