import 'whatwg-fetch';
import querystring from 'querystring';
import fetchJsonp from 'fetch-jsonp';

// import moment from 'moment';

let API = {
  fetchAirports() {
    return fetch('/airports.json').then((response) => response.json());
  },

  async clientGeoIp() {
    let url3 = `/api/geoip`;

    if (process.env.NODE_ENV === 'production') {
      url3 = 'https://gdetotam.org:10006' + url3;
    }

    let response;

    try {
      response = await fetch(url3);
    }
    catch (error) {
      console.error(error);
      return [];
    }

    // console.log(response);
    return response ? response.json() : null;
  },

  async fetchTravels(state) {
    let oprions = {
      q: state.fromAirportCode,
      period: state.startDate,
      min_duration: state.minDays,
      max_duration: state.maxDays
    };

    if (!state.fromAirportCode || state.fromAirportCode.length < 2) {
      console.log("fetchTravels: Invalid input data: ", state);
      return [];
    }

    let generated = querystring.stringify(oprions);
    let url3 = `/api/travels?${generated}`;

    if (process.env.NODE_ENV === 'production') {
      url3 = 'https://gdetotam.org:10006' + url3;
    }

    let response;

    try {
      response = await fetch(url3);
      console.log("fetch response: ", response);
      return response ? response.json() : '';
    }
    catch (error) {
      console.log("Fetch error: ", error);
      return '';
    }
  },

  async nearby(latitude, longitude, len) {
    let oprions = {latitude, longitude, len};
    let generated = querystring.stringify(oprions);

    let url3 = `/api/nearby?${generated}`;

    if (process.env.NODE_ENV === 'production') {
      url3 = 'https://gdetotam.org:10006' + url3;
    }

    let response;

    try {
      response = await fetch(url3);
    }
    catch (error) {
      console.error(error);
      return [];
    }

    // console.log(response);
    return response ? response.json() : '';
  },

  async getAutoComplete(val) {
    // http://autocomplete.travelpayouts.com/places2?term=Mos&locale=en&types[]=country&callback=function

    let oprions = {
      term: val,
      locale: 'en',
      types: [
        'airport'
        // 'city', 'airport', 'country'
      ],
      // callback: 'function'
    };

    let generated = querystring.stringify(oprions);
    let url3 = `https://autocomplete.travelpayouts.com/places2?${generated}`;

    return fetchJsonp(url3, {timeout: 10000}).then(function (response) {
      return response.json();
    }).then((json) => {
      console.log('getAutoComplete json', json);
      return json ? json : '';
    }).catch((error) => {
      console.error("getAutoComplete error:", error);
      return [];
    });
  },

  async getBySearch(val) {
// http://autocomplete.travelpayouts.com/jravia?q=led&with_countries=false

    let oprions = {
      q: val,
      locale: 'en'
      // callback: 'function'
    };

    let generated = querystring.stringify(oprions);
    let url3 = `https://autocomplete.travelpayouts.com/jravia?${generated}`;

    return fetchJsonp(url3, {timeout: 10000}).then(function (response) {
      return response.json();
    }).then(function (json) {
      console.log('getBySearch json', json);
      return json ? json : '';
    }).catch(error => {
      console.error("getBySearch error:", error);
      return [];
    });
  }
};

export default API;
