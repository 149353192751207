import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import XHR from "i18next-http-backend"; // <---- add this
import {DateTime} from 'luxon';

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
};

import commonDe from './locales/de/common.json';
import commonEn from './locales/en/common.json';
import commonRu from './locales/ru/common.json';
import commonFr from './locales/fr/common.json';

const resources = {
  de: {common: commonDe},
  en: {common: commonEn},
  ru: {common: commonRu},
  fr: {common: commonFr}
};

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(XHR) // <---- add this
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    // backend: {
    //   loadPath: "/locales/{{lng}}/common.json"
    //   // loadPath: "/locales/{{lng}}/{{ns}}.json"
    // },
    detection: options,
    resources,
    ns: ['common'],
    defaultNS: 'common',
    fallbackLng: 'en',
    supportedLngs: [
      'de',
      'en',
      'ru',
      'fr'
    ],
    interpolation: {
      escapeValue: false
    }
  });

// new usage
i18n.services.formatter.add('DATE_HUGE', (value, lng, options) => {
  return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime.DATE_HUGE);
});

export default i18n;
