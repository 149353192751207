import React from 'react';
import {geolocated, geoPropTypes} from "react-geolocated";
import ReactTooltip from 'react-tooltip';
import Accordion from 'react-bootstrap/Accordion';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import CircularProgress from '@material-ui/core/CircularProgress';

import {Alert} from "react-bootstrap";
import i18n from 'i18next';

import {useTranslation, Trans, withTranslation} from 'react-i18next';

import interactionPlugin from '@fullcalendar/interaction';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import {IoIosArrowUp} from "react-icons/io";
import {Helmet} from "react-helmet";
import {withRouter} from 'react-router';

import FlighFrom from "./FlighFrom";
import API from '../api/api';
import TravelItem from '../components/TravelItem';
import FilterLine from '../components/FilterLine';
import CAPITALS from '../api/capitals';
import './First.css';
import '../App.css';

import querystring from 'querystring';
import moment from 'moment';
import FilterWrap from './FilterWrap';

const uuid = require("uuid");

const t = i18n.t;

class SmallCard extends React.Component {
  render() {
    const tr = this.props.tr;
    const url = this.makeTraveLUrl(tr);
    const title = `Flight at ${tr.depart_date} from ${tr.country} for ${tr.days} days.`;
    const dd_from = moment(tr.depart_date, 'YYYY-MM-DD').format('DD MMMM, YYYY');
    const dd_to = moment(tr.return_date, 'YYYY-MM-DD').format('DD MMMM, YYYY');

    return (<div className='small-tr'
                 key={'sm-' + tr.id}>
      {/*🛩️*/} <a href={url}
                   title={title}
                   onClick={(e) => this.smallTravelSelected(e, tr)}>
      {tr.city.name_translations.en}, {tr.country}
    </a> - <span
      className="badge badge-pill badge-success">${`${tr.value}`}</span><br/>
      🗓 <small data-tip
                data-for={'tt' + tr.id}><strong>{tr.days}</strong> {t('misc.days')},
      🛫 <strong>{dd_from}</strong></small>
      <ReactTooltip id={'tt' + tr.id}
                    type='light'
                    border
                    effect='solid'>
          <span>
            🗓 <strong>{tr.days}</strong> {t('misc.days')}<br/>
            🛫 <strong>{dd_from}</strong><br/>
            🛬 <strong>{dd_to}</strong>
          </span>
      </ReactTooltip>
    </div>);
  };
}

class AlertDismissible extends React.Component {
  state = {
    show: true
  };

  reset() {
    this.setState({
      show: true
    });
  }

  render() {
    // const {t, i18n} = this.props;

    if (this.state.show) {
      return (
        <Alert className='alert-div' variant={this.props.color} onClose={() => {
          this.setState({show: false});
          this.props.onHide();
        }} dismissible>
          <Alert.Heading>{this.props.errHead}</Alert.Heading>
          <p>
            {this.props.errBody}
          </p>
        </Alert>
      );
    } else {
      return null;
    }
  };
}

class First extends React.Component {
  calRef = React.createRef();
  calendarRef = React.createRef();
  // t = this.props.t;
  // i18n = this.props.i18n;
  // starting = null;
  // startDate = null;

  state = {
    countryName: '',
    countryCode: '',
    city: '',
    apName: '',
    latitude: 0,
    longitude: 0,
    nearby: [],
    searchList: [],
    selected: null,
    fromAirportCode: null,
    fetchTravels: [],
    travelsCalendar: [],
    sortBy: 'date',
    startDate: null,
    minDays: 1,
    maxDays: 15,
    startingMonth: 0,
    fromLabel: null,
    error: null,
    capitals: CAPITALS.getRandom(10),
    locationDefined: false,
    loading: false
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handeleScroll);
  }

  componentDidMount() {
    let _this = this;
    const {match: {params}} = this.props;

    if (this.props.match.params) {
      console.log("this.props.match.params = ", params);
    } else {
      console.info("No params arrived");
    }

    window.addEventListener("scroll", this.handeleScroll);
    window.scrollTo(0, 0);

    async function init() {
      return await _this.initLists();
    }

    init().then((res) => {
      console.log("Init func done.");

      setTimeout(async () => {
        try {
          await _this.processParams(res);
          await _this.getLocationDetails();
          await _this.getNearbyAirports(res);
        }
        catch (e) {
          console.error(e);
        }

      }, 2000);
    });
  }

  initLists = async () => {
    this.setState({
      loading: false
    });
  };

  processParams = async (obj) => {
    try {
      if (!this.props.match.params) {
        return;
      }

      if (this.props.match.params.starting) {
        await this.processStartingParams(this.props.match.params.starting);
      }

      if (this.props.match.params.source) {
        const input = await API.getBySearch(this.props.match.params.source);
        await this.processSourceParams(input);
      }
    }
    catch (e) {
      console.error(e);
    }
  };

  processStartingParams = async (input) => {
    const starting = moment(input, 'YYYY-MM-DD');

    if (!starting.isValid()) {
      console.log("Input param % is not valid month", input);
      return;
    }

    console.log("Using starting date ", starting);

    let startDate = starting.format('YYYY-MM-DD');
    let startMonth = parseInt(starting.format('MM'), 10);

    this.setState({'startDate': startDate});
    this.setState({'startingMonth': startMonth + 1});
  };

  processSourceParams = async (selected) => {
    if (!selected || !selected.length) {
      this.setState({
        error: {
          header: t(errors['Selected airport not found']),
          body: t(errors['please select another airport of departure']),
          color: 'warning'
        }
      });

      return;
    }

    this.setState({
      loading: true
    });

    let inStr = this.props.match.params.source;

    // let nearby = await API.nearby(input.latitude, input.longitude, 200);

    let airports = selected.sort((a, b) => {
      if (b.name && !a.name) {
        return 1;
      }
      if (b._type === 'city' && a._type === 'airport') {
        return 1;
      }
      if (a._type === 'city' && b._type === 'airport') {
        return -1;
      }

      return (b._score - a._score);
    });

    const inAirport = selected.find((a) => (a.code === inStr));
    const input = inAirport ? inAirport : airports[0];
    const code = input.code ? input.code : input.city_code;
    const city_name = input.city_name ? input.city_name : input.name;

    console.log("processSourceParams input = ", input);

    let inputStr = `${city_name}, ${input.country_name} (${code})`;

    this.setState({
      selected: input,
      fromLabel: inputStr,
      apName: input.name,
      fromAirportCode: input.iata,
      loading: false,
      inputStr: inputStr
    });

    console.log("Run nearby with coords :", input.coordinates.lat, input.coordinates.lon);
    API.nearby(input.coordinates.lat, input.coordinates.lon, 300).then(this.setNearbyAirports);

    let _this = this;

    this.onItemClick(input).then(() => {
      try {
        _this.calRef.current.scrollIntoView({behavior: 'smooth'});
      }
      catch (err) {
        //
      }
    });
  };

  setNearbyAirports = (list) => {
    console.log("Received nearby: ", list);
    if (!list || !list.length) {
      return;
    }
    this.setState({
      nearby: list
    });
  };

  getNearbyAirports = async (obj) => {
    // console.log("getNearbyAirports: ", this.state.latitude, this.state.longitude);
    let nearby = [];

    if (!this.state.locationDefined) {
      console.log("getNearbyAirports: No location available.");
    } else {
      nearby = await API.nearby(this.state.latitude, this.state.longitude, 200);
      console.log("getNearbyAirports loade nearby!");
    }

    this.setNearbyAirports(nearby || []);
  };

  handleChangeMinDays = (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    console.log("handleChangeMinDays returned: ", fieldName, fleldVal);
    this.setState({'minDays': fleldVal});
  };

  handleChangeMaxDays = (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    console.log("handleChangeMaxDays returned: ", fieldName, fleldVal);
    this.setState({'maxDays': fleldVal});
  };

  handleChangeStartingMonth = (event) => {
    // let fieldName = event.target.name;
    let fleldVal = event.target.value;

    let startDate = moment().add(fleldVal, 'M').format('YYYY-MM-DD');
    console.log("handleChangeStartingMonth returned: ", fleldVal, startDate);

    this.setState({'startDate': startDate});
    this.setState({'startingMonth': fleldVal});
  };

  updNearBy = async (code) => {
    // const {t, i18n} = this.props;
    let list = await API.getAutoComplete(code);

    if (!list || !list.length) {
      console.log("Invalid code data:", code);

      this.setState({
        error: {
          header: t(errors['Invalid airport of origin : ']) + code,
          body: t(errors['please select another airport of departure']),
          color: 'warning'
        }
      });
      return '';
    }

    if (list.length > 1) {
      console.log("The list is too big:", list);
      return '';
    }

    const input = list[0];

    if (!input.hasOwnProperty('coordinates')) {
      console.log("No coordinates available.");
      return '';
    }

    let nearby = await API.nearby(input.coordinates.lat, input.coordinates.lon, 200);
    this.setNearbyAirports(nearby || []);

    return input.name;
  };

  parseInput = (str) => {
    // ${option.name}, ${option.country_name} (${option.code})
    console.log("Received str = ", str);

    if (typeof str === 'object') {
      return str;
    }

    let code = str.match(/(.*?), (.*?) \((.*)\)/);

    if (code && code.length) {
      console.log("Set fromAirportCode to ", code);

      let name = code[1];
      let desc = code[2];
      // let country = code[3];
      let iata = code[3];

      if (iata) {
        const rec = {
          fromAirportCode: iata,
          code: iata,
          fromLabel: desc,
          apName: name,
          inputStr: str
        };

        this.setState(rec);

        this.onItemClick(rec).then(() => {
          console.log("Updated search.");
        });

        return rec;
      }
    }

    return null;
  };

  onAirportUpdate = async (str) => {
    if (str === null || str === undefined || str === '') {
      return;
    }

    if (str.length < 2) {
      return;
    }

    console.log("onAirportUpdate:", str);
    if (this.parseInput(str)) {
      return;
    }

    let result = await API.getAutoComplete(str);
    this.setState({
      searchList: result
    });
  };

  smallTravelSelected = (e, travel) => {
    e.preventDefault();
    console.log("smallTravelSelected:", travel);
    travel.ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
    // window.scrollBy(0, -65);
    // travel.ref.current.divRef.current.scrollIntoView({behavior: 'smooth'});
  };

  makeTraveLUrl = (travel) => {

    let oprions = {
      origin_iata: this.state.fromAirportCode,
      destination_iata: travel.destination,
      depart_date: travel.depart_date,
      return_date: travel.return_date,
      oneway: 0,
      adults: 1,
      children: 0,
      infants: 0,
      trip_class: 0,
      marker: 20331,
      with_request: true,
      currency: 'usd',
      locale: i18n.language //'en'
    };

    let generated = querystring.stringify(oprions);

    // const URL = `http://hydra.aviasales.ru/searches/new?${generated}`;
    return `http://travel.gdetotam.org/searches/new?${generated}`;
  };

  travelSelected = (travel) => {
    window.open(this.makeTraveLUrl(travel), "_blank");
  };

  onCalendarClick = async (info) => {
    this.travelSelected(info.event.extendedProps.travel);
  };

  onItemClick = async (item) => {
    if (!item) {
      console.log("Item not defined!!!");
      return;
    }

    console.log("onItemClick Input = ", item);

    const input = this.parseInput(item);

    if (!input) {
      this.setState({
        error: {
          header: 'Airport code is invalid',
          body: 'Please select another airport of departure',
          color: 'warning'
        }
      });

      this.setState({
        fetchTravels: [],
        travelsCalendar: []
        // nearby: [],
      });

      return;
    }

    const fromAirportCode = input.code;

    this.setState({
      error: null,
      fromAirportCode: fromAirportCode
    });

    if (this.state.startDate) {
      this.props.history.push(`/from/${fromAirportCode}/since/${this.state.startDate}`);
    } else {
      this.props.history.push(`/from/${fromAirportCode}`);
    }

    this.setState({
      loading: true
    });

    const options = {
      fromAirportCode: fromAirportCode,
      startDate: this.state.startDate,
      minDays: this.state.minDays,
      maxDays: this.state.maxDays
    };

    const myJson = await API.fetchTravels(options);

    if (!myJson || !myJson.success) {
      let errMsg = 'Server returned the error, possibly issue with firewall?';

      if (myJson.error) {
        if (myJson.error.includes('Unknown place iata')) {
          errMsg = "Invalid airport : " + this.state.fromAirportCode;
          this.setState({
            error: {
              header: 'Airport data not valid',
              body: errMsg,
              color: 'warning'
            },
            fetchTravels: [],
            travelsCalendar: [],
            // nearby: [],
            loading: false
          });

          return;
        }
      }

      // other error treatment:
      console.log("Error returned", JSON.stringify(myJson, null, 2));

      this.setState({
        error: {
          header: 'Server error',
          body: errMsg,
          color: 'danger'
        },
        fetchTravels: [],
        // nearby: [],
        travelsCalendar: [],
        loading: false
      });

      return;
    }

    if (!myJson.data || !myJson.data.length) {
      console.log("Empty travels returned", JSON.stringify(myJson, null, 2));
      this.setState({
        error: {
          header: 'No travels found',
          body: 'Sorry, we didn\'t find any travel for you, please try different airport or adjust a filter',
          color: 'info'
        },
        fetchTravels: [],
        // nearby: [],
        travelsCalendar: [],
        loading: false
      });

      return;
    }

    const anAsyncFunction = async (item, index) => {
      item.id = "tr-" + index + '-' + uuid.v4();
      item.ref = React.createRef();
      return item;
    };

    let getItems = async () => {
      return Promise.all(myJson.data.map(async (item, index) => anAsyncFunction(item, index)));
    };

    let items = await getItems();

    items = items.sort((a, b) => {
      return new Date(a.depart_date) - new Date(b.depart_date);
    }).filter((a) => {
      return a.value <= 200;
    });

    let travelsCalendar = items.map(travel => {
      if (!travel.airport) {
        return null;
      }

      let city = travel.city.name_translations.en;
      let country = travel.country;

      return {
        id: travel.id,
        title: `${city}, ${country} $${travel.value}`,
        start: travel.depart_date,
        end: travel.return_date,
        className: 'bg-success text-white text-center',
        travel: travel
      };
    }).filter(c => c);

    this.setState({
      fetchTravels: items,
      travelsCalendar: travelsCalendar,
      loading: false
    });

    this.calRef.current.scrollIntoView({behavior: 'smooth'});

    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.gotoDate(this.state.fetchTravels[0].depart_date);
  };

  handleSubmit(event) {
    console.log("Submit button pressed.");
    event.preventDefault();
    return false;
  }

  defineCurrentLocation = async (latitude, longitude) => {
    console.log("defineCurrentLocation got ", latitude, longitude);
    this.setState({
      latitude: latitude,
      longitude: longitude,
      locationDefined: true
    });
  };

  getLocationDetails = async () => {
    if (!this.props.isGeolocationAvailable || !this.props.isGeolocationEnabled || !this.props.coords) {
      console.log("getLocationDetails: No geo present?");

      let geo = await API.clientGeoIp();
      console.log("Server GeoIP: ", geo);

      return;
    }

    return this.defineCurrentLocation(this.props.coords.latitude, this.props.coords.longitude);
  };

  eventMouseEnter(info) {
    // console.log("Show tooltip : ", info.event.title);
    // info.el.setAttribute("data-tip", info.event.title);
    //
    // ReactTooltip.rebuild();
  }

  handeleScroll() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById("topBtn").style.display = "block";
    } else {
      document.getElementById("topBtn").style.display = "none";
    }
  }

  topFunction() {
    console.log("Scroll to top");
    // window.scrollTo(0,0);
    window.scrollTo({top: 0, behavior: "smooth"});
  }

  setSortBy = (flag) => {
    console.log("SortBy:", flag);
    this.setState({'sortBy': flag});
  };

  onStartMonth = (num) => {
    console.log("start month offset = ", num);
    this.setState({'startDate': moment().add(num, 'M').format('YYYY-MM-DD')});
  };

  makeStructuredJson = (travels) => {
    if (!travels || !travels.length) {
      return null;
    }

    let startUrl = `https://gdetotam.org/from/${this.state.fromAirportCode}`;

    if (this.state.startDate) {
      startUrl = startUrl + '/since/' + this.state.startDate;
    }

    const titleHdr = t("Flight There and Back under") + " $100";
    // const title = `${titleHdr} from ${lbl}`;

    let dataHeader = {
      "@context": "http://schema.org/",
      "@graph": []
    };

    dataHeader["@graph"] = (travels || []).map((tr) => {
      const url = this.makeTraveLUrl(tr);
      const title = `Flight from ${this.state.fromLabel} at ${tr.depart_date} to ${tr.city.name_translations.en},${tr.country} for ${tr.days} days.`;
      const desc = `${titleHdr}: flight from ${this.state.fromLabel} at ${tr.depart_date} to ${tr.country} for ${tr.days} days.`;

      // console.log("processing travel:", tr);

      return {
        "@context": "https://schema.org",
        "@type": "TouristTrip",
        "@id": tr.id,
        "name": title,
        "description": title,
        "arrivalTime": tr.depart_date,
        "departureTime": tr.return_date,
        "image": tr.image_big,
        "touristType": [
          "Cultural tourism",
          "Urban tourism",
          "Nightlife tourism",
          "Shopping tourism"
        ],
        "subjectOf": {
          "@type": "CreativeWork",
          "name": desc,
          "url": startUrl
        },
        "itinerary": [{
          "@type": "City",
          "name": tr.city.name_translations.en,
          "url": `https://gdetotam.org/from/${tr.airport.code}`
        }],
        "offers": {
          "@type": "Offer",
          "name": title,
          "description": desc,
          "price": tr.value,
          "priceCurrency": "USD",
          "url": url,
          "eligibleRegion": {
            "@type": "Country",
            "name": tr.country
          }
        }
      };
    });

    return JSON.stringify(dataHeader, null, 2);
  };

  handleEventPositioned(info) {
    // console.log("handleEventPositioned tooltip : ", info.event);
    let days = moment(info.event.end).diff(moment(info.event.start), 'days');
    info.el.setAttribute("data-tip", info.event.title + ', ' + days + ' days');
    ReactTooltip.rebuild();
  }

  render() {
    let travelsSorted = this.state.fetchTravels.sort((a, b) => {
      if (this.state.sortBy === 'date') {
        return new Date(a.depart_date) - new Date(b.depart_date);
      } else {
        return a.value - b.value;
      }
    });

    const renderSmalLst = (tr) => {
      const url = this.makeTraveLUrl(tr);
      const title = `Flight at ${tr.depart_date} from ${tr.country} for ${tr.days} days.`;
      const dd_from = moment(tr.depart_date, 'YYYY-MM-DD').format('DD MMMM, YYYY');
      const dd_to = moment(tr.return_date, 'YYYY-MM-DD').format('DD MMMM, YYYY');

      return (<div className='small-tr /*text-center*/'
                   key={'sm-' + tr.id}>
        {/*🛩️*/} <a href={url}
                     title={title}
                     onClick={(e) => this.smallTravelSelected(e, tr)}>
        {tr.city.name_translations.en}, {tr.country}
      </a> - <span
        className="badge badge-pill badge-success">${`${tr.value}`}</span><br/>
        🗓 <small data-tip
                  data-for={'tt' + tr.id}><strong>{tr.days}</strong> {t('misc.days')},
        🛫 <strong>{dd_from}</strong></small>
        <ReactTooltip id={'tt' + tr.id}
                      type='light'
                      border
                      effect='solid'>
          <span>
            🗓 <strong>{tr.days}</strong> {t('misc.days')}<br/>
            🛫 <strong>{dd_from}</strong><br/>
            🛬 <strong>{dd_to}</strong>
          </span>
        </ReactTooltip>
      </div>);
    };

    const sl = (travelsSorted || []).map((tr, index) => {
      return renderSmalLst(tr);
    });

    const smallList = <div className='smallList-container'>{sl}</div>;

    //   {
    //     // const tr = this.props.tr;
    //     const url = this.makeTraveLUrl(tr);
    //     const title = `Flight at ${tr.depart_date} from ${tr.country} for ${tr.days} days.`;
    //     const dd_from = moment(tr.depart_date, 'YYYY-MM-DD').format('DD MMMM, YYYY');
    //     const dd_to = moment(tr.return_date, 'YYYY-MM-DD').format('DD MMMM, YYYY');
    //
    //     return (<div className='small-tr'
    //                  key={'sm-' + tr.id}>
    //       {/*🛩️*/} <a href={url}
    //                    title={title}
    //                    onClick={(e) => this.smallTravelSelected(e, tr)}>
    //       {tr.city.name_translations.en}, {tr.country}
    //     </a> - <span
    //       className="badge badge-pill badge-success">${`${tr.value}`}</span><br/>
    //       🗓 <small data-tip
    //                 data-for={'tt' + tr.id}><strong>{tr.days}</strong> days, 🛫 <strong>{dd_from}</strong></small>
    //       <ReactTooltip id={'tt' + tr.id}
    //                     type='light'
    //                     border
    //                     effect='solid'>
    //       <span>
    //         🗓 <strong>{tr.days}</strong> days<br/>
    //         🛫 <strong>{dd_from}</strong><br/>
    //         🛬 <strong>{dd_to}</strong>
    //       </span>
    //       </ReactTooltip>
    //     </div>);
    //   });
    //
    //   // return list;
    //   // return (<div className='smallList-container'>{list}</div>);
    // };

    let TravelList = travelsSorted.map((tr) => (<TravelItem
      travel={tr}
      key={tr.id}
      // ref={tr.ref}
      onSelect={this.travelSelected}
      onCalendarSelect={this.onCalendarClick}
    />));

    let error;
    if (this.state.error) {
      error =
        <AlertDismissible
          errHead={this.state.error.header}
          errBody={this.state.error.body}
          color={this.state.error.color}
          onHide={() => {
            this.setState({error: null});
          }
          }/>;
    }

    let headers;
    let sourceLabel;

    if (this.state.fromLabel) {
      let lbl = this.state.fromLabel + ` (${this.state.apName})`;
      let url = `https://gdetotam.org/from/${this.state.fromAirportCode}`;

      if (this.state.startDate) {
        lbl = lbl + ' starting from ' + this.state.startDate;
        url = url + '/since/' + this.state.startDate;
      }

      const titleHdr = t("Flight There and Back under") + " $100";
      const title = `${titleHdr} ${t("from")} ${lbl}`;
      const structData = TravelList ? (
        <script className='structured-data-list' type="application/ld+json">
          {this.makeStructuredJson(travelsSorted)}
        </script>) : '';

      sourceLabel = <> from {this.state.fromLabel} {this.state.apName} </>;

      headers = <Helmet>
        <meta name="description" content={title}/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={title}/>

        <meta content={title} name="twitter:title"/>
        <meta content={title} name="twitter:description"/>
        <title>{title}</title>
        <link rel="canonical" href={url}/>

        <meta name="audience" content="all"/>
        <meta name="HandheldFriendly" content="True"/>
        <meta name="MobileOptimized" content="320"/>

        <meta property="og:site_name" content={titleHdr}/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={title}/>

        <meta property="og:url" content={url}/>

        <meta name="abstract" content={title}/>
        <meta name="page-topic" content={title}/>

        <meta name="twitter:card" content={title}/>
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={title}/>
        <meta name="twitter:url" content={url}/>

        {structData}

      </Helmet>;
    }

    let cal;
    let localStartDate = this.state.startDate ? moment(this.state.startDate, 'YYYY-MM-DD').format('MMMM, YYYY') : moment().format('MMMM, YYYY');
    let capitals_list = <div className="capitails justify-content-md-center">
      <div className="row">{this.state.capitals.map((ap, index) => {
        return <div className="capital col-1" key={ap + '-' + index}><a
          title={t("Flight There and Back under") + " $100 " + t("from") + ` ${ap}`}
          href={`/from/${ap}`}>{ap}</a></div>;
      })}</div>
    </div>;

    let flighFromList;
    if (this.state.nearby && this.state.nearby.length) {
      let nearbyItems = this.state.nearby.map((ap, index) => {
        return <div className="nearBy col-md-2" key={'nb-' + index}><a
          title={t("Flight There and Back under") + " $100 " + t("from") + ` ${ap.name}`}
          href={`/from/${ap.iata}`}>{`${ap.name}`}</a></div>;
      });
      flighFromList = <div className="container">
        <span>{i18n.t('misc.try_different')}: </span><br/>
        <div className="row nearByBlock" key="nearByItems">
          {nearbyItems}
        </div>
        {capitals_list}
      </div>;
    } else {
      console.log("nearby not present!");
    }

    if (this.state.travelsCalendar.length) {
      cal =
        <div id='mainCalendar'>
          <div ref={this.calRef}/>
          <ReactTooltip/>
          <div className='small-list text-muted'>
            <h2 className="bodySubTitle text-muted text-left">
              Found {this.state.travelsCalendar.length} {t("options beginning")} at {localStartDate}:
            </h2>
            {smallList}
          </div>
          <Accordion defaultActiveKey="mainCalendar">
            <FilterLine onSort={this.setSortBy} onStartMonth={this.onStartMonth}/>

            <Accordion.Collapse eventKey="mainCalendar">
              <div id="calendarId">
                <div className="calendar-in">
                  <div className="calendar-in">
                    <FullCalendar
                      defaultView="dayGridMonth"
                      plugins={[/*BootstrapTheme, */dayGridPlugin, interactionPlugin]}
                      weekends={true}
                      events={this.state.travelsCalendar}
                      eventMouseEnter={this.eventMouseEnter}
                      aspectRatio={2}
                      defaultDate={this.state.fetchTravels[0].depart_date}
                      eventPositioned={this.handleEventPositioned}
                      id="big-calendar"
                      ref={this.calendarRef}
                      eventClick={this.onCalendarClick}/>
                    {/*<span className='text-muted small'>all-in-one calendar</span>*/}
                  </div>
                </div>
              </div>
            </Accordion.Collapse>
          </Accordion>
        </div>;
    }

    return (
      <div className="App">
        {headers}
        <header className="App-header">
          <div className='container text-center'>
            <div className='App-logo'>{t("Flight There and Back under")} $100</div>
            {sourceLabel}
            <FlighFrom
              searchList={this.state.searchList}
              nearby={this.state.nearby}
              label={this.state.inputStr ? this.state.inputStr : 'Flying from?'}
              onItemClick={this.onItemClick}
              update={this.onAirportUpdate}
              input={this.state.inputStr}
              location={this.props.coords}/>
            <FilterWrap
              handleChangeMinDays={this.handleChangeMinDays}
              MinDays={this.state.minDays}
              handleChangeMaxDays={this.handleChangeMaxDays}
              MaxDays={this.state.maxDays}
              handleChangeStartingMonth={this.handleChangeStartingMonth}
              StartingMonth={this.state.startingMonth}
            />
            {error}
            {this.state.loading && <CircularProgress/>}
          </div>
        </header>

        <div className="bodyTitle">
          {t("Flight There and Back under")} $100
          {sourceLabel}
        </div>

        <div className='travels-found' id='travels'>
          <div className='calendar-events'>
            {cal}
          </div>
          <div className='travels-list' id='tr-list'>
            {TravelList}
          </div>
          <button
            onClick={this.topFunction}
            id="topBtn"
            title="Go to top">
            <IoIosArrowUp/>
          </button>
        </div>

        <div className="jumbotron">
          {flighFromList}
        </div>
      </div>
    );
  }
}


First.propTypes = {
  ...First.propTypes,
  ...geoPropTypes
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false
  },
  userDecisionTimeout: 5000
})(withRouter(First));
