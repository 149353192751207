/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useTranslation} from "react-i18next";

import './FighFrom.css';
import {Col, Form, FormLabel} from 'react-bootstrap';

class FlighFrom extends React.Component {
  state = {value: null};

  getLabel = (option) => {
    if (this.props.searchList && this.props.searchList.length) {
      return `${option.name}, ${option.country_name} (${option.code})`;
    }
    return `${option.name}, ${option.city} ${option.country} (${option.iata})`;
  };

  render() {
    const label = <FormLabel title={this.props.label} style={{
      // color: 'red',
      fontWeight: 'bold',
      backgroundColor: "white",
      paddingLeft: 15
    }}>{this.props.label}</FormLabel>;

    return (
      <Form.Row className="justify-content-md-center ff-form">
        <Col className='flightFrom'>
          <Autocomplete
            options={this.props.searchList && this.props.searchList.length ? this.props.searchList : (this.props.nearby || [])}
            getOptionLabel={this.getLabel}
            id="from-airport"
            onInputChange={(evt, value) => {
              this.setState({
                value: value
              });
              this.props.update(value);
            }}
            // defaultValue={this.props.input}
            // value={this.props.input}
            clearOnEscape
            autoComplete
            renderInput={params => (
              <TextField {...params}
                         label={label}
                         defaultValue={this.props.input}
                         margin="normal"
                         style={{
                           backgroundColor: "white",
                           padding: 5
                         }}
                         fullWidth/>
            )}
          />
        </Col>
        <Col sm={2}>
          <a className="btn btn-info js-scroll-trigger find-fligh-btn"
             href='#travels'
             onClick={() => {
               this.props.onItemClick(this.state.value || this.props.input);
             }}
          >Find routes
          </a>
        </Col>
      </Form.Row>
    );
  }
}

export default FlighFrom;
